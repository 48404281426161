html body .app.flex-row.align-items-center {
  height: 100vh;
}

// ie11 floating footer temp fix, react only
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #root {
    display: flex;
    flex-direction: column;
  }
}

@media(max-width:767px){ .card { border: 0px}}
@media(min-width:768px){ .card { border: 0px}}
@media(min-width:992px){ .card { border:1px solid #c8ced3}}
@media(min-width:1200px){ .card { border:1px solid #c8ced3}}
// Here you can add other styles
.ludwig {
  position: relative;
  padding-left: 1em;
  border-left: 0.2em solid lighten($black, 40%);
  &--quote {
    &:before,
    &:after {
      content: "\201C";
      font-family: "Sanchez";
      color: lighten($black, 40%);
    }
    &:after {
      content: "\201D";
    }
  }
}

.o-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  text-transform: capitalize;
}

label span {
  text-transform: capitalize;
}

.navbar-brand-full {
  height: 100%;
  width: 100%;
  object-fit: contain;
  padding: 0 10px;
}

.main .container-fluid {
  @include media-breakpoint-down(md) {
    padding-left: 0;
    padding-right: 0;
  }
}
